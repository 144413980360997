import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule} from '@angular/fire/';
import {  AngularFireAuth } from '@angular/fire/auth';
import 'hammerjs';


import { AppComponent } from './app.component';

import { AuthService } from './utils/services/auth.service';
import { NavigationService } from './utils/services/navigation.service';

import { UserService } from './utils/services/user.service';
import { SeoService } from './utils/services/seo.service';
import { AuthGuard } from './gards/auth-guard.service';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app.routing';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NotificationService } from './utils/services/notification.service';
import { ClickOutsideDirective } from './utils/directives/click-outside.directive';
import { FitImageDirective } from './utils/directives/fit-image.directive';
import { KeepSquareDirective } from './utils/directives/keep-square.directive';
import { FitChildrenDirective } from './utils/directives/fit-children.directive';
import { IntegerInputDirective } from './utils/directives/integer-input.directive';
import { ShipengineService } from './utils/services/shipengine.service';
import { ValidationService } from './utils/services/validation.service';

import { ImageViewComponent } from './utils/components/image-view/image-view.component';
import { ImageModalService } from './utils/services/image-modal.service';

import { NotificationPopupComponent } from './utils/components/notification-popup/notification-popup.component';
import { InfiniteService } from './utils/services/infinite.service';
import { TitleCasePipe } from '@angular/common';
import { SidebarComponent } from './utils/components/sidebar/sidebar.component';
import { SidebarService } from './utils/components/sidebar/sidebar.service';
import { DropdownComponent } from './utils/components/dropdown/dropdown.component';
import { ImportProductService } from './utils/components/import-product/services/import-product.service';
import { SnsService } from './utils/shared-modules/vieworder/services/sns.service';
import { HttpClientModule } from '@angular/common/http';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { PricingService } from './utils/shared-modules/vieworder/services/pricing.service';
import { SizesService } from './utils/shared-modules/vieworder/services/sizes.service';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/storage';
import { NgxCarouselModule } from 'ngx-light-carousel';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { TooltipModule } from 'ng2-tooltip-directive';
import { UsePipesModule } from './utils/shared-modules/use-pipes/use-pipes.module';
import { ChatsModule } from './modules/chats/chats.module';
import { ChatNotificationPopupComponent } from './utils/components/chat-notification-popup/chat-notification-popup.component'

import { PPDashboardModule } from './modules/ppdashboard/ppdashboard.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MetaModule } from '@ngx-meta/core';
import { NavOneLayoutComponent } from './layout/nav-one-layout/nav-one-layout.component';
import { NavTwoLayoutComponent } from './layout/nav-two-layout/nav-two-layout.component';

// const authConfig = {
//   provider: AngularFireAuth.,
//   method: AuthMethods.Popup // Change to AuthMethods.Redirect if you'd rather have a redirect
// };

@NgModule({
  declarations: [
    AppComponent,
    ClickOutsideDirective,
    FitImageDirective,
    FitChildrenDirective,
    KeepSquareDirective,
    IntegerInputDirective,
    ImageViewComponent,
    NotificationPopupComponent,
    ChatNotificationPopupComponent,
    SidebarComponent,
    DropdownComponent,
    NavOneLayoutComponent,
    NavTwoLayoutComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    InfiniteScrollModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // AngularFireModule.initializeApp(environment.config, authConfig),
    AngularFireModule.initializeApp(environment.config),
    AngularFireStorageModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    NgxCarouselModule,
    IvyCarouselModule,
    TooltipModule,
    UsePipesModule,
    ChatsModule,
    PPDashboardModule,
    NgSelectModule,
    NgOptionHighlightModule,
    CarouselModule,
    MetaModule.forRoot()
  ],
  providers: [
    AuthGuard,
    AuthService,
    NavigationService,
    NotificationService,
    UserService,
    ShipengineService,
    ValidationService,
    ImageModalService,
    InfiniteService,
    TitleCasePipe,
    SidebarService,
    ImportProductService,
    SnsService,
    PricingService,
    SizesService,
    SeoService,
    { provide: BUCKET, useValue: environment.config.storageBucket }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    //
  }
}
