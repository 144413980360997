import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../utils/services/common.service';
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import * as _ from 'lodash'
import { NotificationService } from '../../utils/services/notification.service';
import { NavigationService } from '../../utils/services/navigation.service';
import { InfiniteService } from '../../utils/services/infinite.service';
import { SidebarService } from '../../utils/components/sidebar/sidebar.service';

import '@polymer/paper-input/paper-input';
import '@polymer/app-layout/app-layout';
import '@polymer/gold-phone-input/gold-phone-input';
import '@polymer/iron-flex-layout/iron-flex-layout';
import '@polymer/iron-icons/iron-icons';
import '@polymer/iron-list/iron-list';
import '@polymer/paper-badge/paper-badge';
import '@polymer/paper-button/paper-button';
import '@polymer/paper-card/paper-card';
import '@polymer/paper-checkbox/paper-checkbox';
import '@polymer/paper-dialog/paper-dialog';
import '@polymer/paper-dropdown-menu/paper-dropdown-menu';
import '@polymer/paper-icon-button/paper-icon-button';
import '@polymer/paper-item/paper-item';
import '@polymer/paper-listbox/paper-listbox';
import '@polymer/paper-progress/paper-progress';
import '@polymer/paper-spinner/paper-spinner';
import '@polymer/paper-styles/paper-styles';
import '@polymer/paper-swatch-picker/paper-swatch-picker';
import '@polymer/paper-tabs/paper-tabs';
import '@polymer/paper-toast/paper-toast';
import '@polymer/paper-toggle-button/paper-toggle-button';
import '@polymer/paper-toolbar/paper-toolbar';
import '@polymer/paper-tooltip/paper-tooltip';
import { CommonAPIService } from '../../utils/services/common-api.service';
import { ChatsService } from '../../utils/services/chats.service';
import { SizesService } from '../../utils/shared-modules/vieworder/services/sizes.service';
import { OrderService } from '../../modules/sales/order.service';
import { AuthService } from 'app/utils/services/auth.service';

declare let window: any;
declare const $: any;
declare global {
  interface Window {
    hsConversationsSettings: any;
    HubSpotConversations: any;
    hsConversationsOnReady: any[];
  }
}

var config = {
  apiKey: "AIzaSyD0sg7sixNNiicGTa03_2wVmznjxsXfRSg",
  authDomain: "upmerch-34a26.firebaseapp.com",
  databaseURL: "https://upmerch-34a26.firebaseio.com",
  projectId: "upmerch-34a26",
  storageBucket: "upmerch-34a26.appspot.com",
  messagingSenderId: "621223658221"
};

@Component({
  selector: 'app-nav-one-layout',
  templateUrl: './nav-one-layout.component.html',
  styleUrls: ['./nav-one-layout.component.scss']
})
export class NavOneLayoutComponent implements OnInit {
  route: string = "sales";
  editPath: string = '/sales/';
  auth: any;
  notifications: any;
  unreadNotifications: any;
  hideNotifications: boolean;
  user: any;
  isSideMenuOpen: boolean = false;

  isSidebarOpen: boolean;
  searchIsActive = false;
  searchData: any = '';
  brands = [
    'Gildan',
    'Bella + Canvas',
    'Comfort Colors',
    'Next Level',
    'American Apparel',
    'Champion',
    'Jerzees',
    'Yupoong',
    'Adidas',
    'Augusta Sportswear'
  ];
  categories = [
    'Short Sleeve',
    'Long Sleeve',
    'Tank Top',
    'Crewneck',
    'Hooded',
    'Headwear',
    'Full-Zip',
    'Quarter Zip',
    '3/4 Sleeve',
    'Face Mask'
  ];
  isModalOpen = false;
  constructor(
    public authService: AuthService,
    private af: AngularFireAuth,
    private router: Router,
    public nav: NavigationService,
    private notificationService: NotificationService,
    private InfityService: InfiniteService,
    private sidebarService: SidebarService,
    private activatedRoute: ActivatedRoute,
    private commonAPIService: CommonAPIService,
    public chatsService: ChatsService,
    private sizesService: SizesService,
    public orderService: OrderService,
    private commonService: CommonService
  ) {
    window.subscriptions = [];
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.route = event.url.split("/")[1]
        console.log(this.route);
        // if(authService.isUserAuthorized())
        //   this.chatsService.fetchUnreadChats();
      }
    });
    // this.activatedRoute.queryParams.subscribe(params => {
    //   this.searchData = (params['searchString'] !== undefined) ? params['searchString'] : '';
    //   // console.log('searchData =', this.searchData);
    // });
    this.authService.isModalOpen.subscribe((x) => {
      if (x) {
        this.isModalOpen = x;
        if (this.isModalOpen) {
          document.querySelector("body").style.overflow = "auto";
        }
      }
      else {
        document.querySelector("body").style.overflow = "hidden";
        this.isModalOpen = false;
      }
    });
  }


  async ngOnInit() {
    await this.commonAPIService.getPpCompanies();
    await this.commonAPIService.getListProductionPartners();
    await this.commonAPIService.initializeMethodsAndPricing();
    localStorage.removeItem('search_types');
    this.notifications = [];
    this.hideNotifications = true;
    this.unreadNotifications = [];
    this.sidebarService.subscribe.subscribe(res => {
      this.isSidebarOpen = res;
    });
    this.af.user.subscribe(async (auth) => {
      this.auth = auth;

      if (this.auth) {
        // if the authService has been initialized
        this.authService.checkAuthAndCallback(auth => {

          if (this.authService.isUserAuthorized()) {
            // this.chatsService.fetchUnreadChats()
            // if they are a design partner
            if (this.authService.isUserDesignPartner()) {
              this.editPath = '/design/';
              // if they are a prod partner
            } else if (this.authService.isUserProductionPartner()) {
              this.editPath = '/production/';
            }
          }
        });
        // this.db.object(`/users/${this.auth.uid}`).valueChanges().subscribe(user => {
        //   this.user = user;
        // });
        this.user = await this.commonAPIService.fnGetUserByEmail(this.auth.email);
        this.brands.sort();
        this.categories.sort();
      }
      this.sizesService.getAllSizes();
    });
    this.nav.isIOSDevice = this.isIOS();
  }

  isIOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  checkRoute() {
    let backgroundWhiteRoutes = ['p', 'p/edit', 'admin-product-list', 'products'];
    var routestring = this.route.split('?')[0];
    return backgroundWhiteRoutes.indexOf(routestring) >= 0;
  }

  onScroll() {
    this.InfityService.scroll();
  }
  showSearch() {

    if (this.router.url === 'products/category/') {
      this.commonService.searchFor = this.commonService.searchFor === 'secondaryCategories' ? '' : 'secondaryCategories';
    } else if (this.router.url.includes('/')) {
      this.commonService.searchFor = this.commonService.searchFor === 'primaryCategories' ? '' : 'primaryCategories';
    } else {
      this.searchIsActive = !this.searchIsActive;
    }
  }
  toggleSidebar() {
    this.sidebarService.toggle();
  }

  toggleSideMenu() {
    this.isSideMenuOpen = !this.isSideMenuOpen;
  }

  makeActive(route: string) {
    this.route = route
    this.router.navigateByUrl(route)
  }

  toggleNotificationDropdown() {
    this.hideNotifications = !this.hideNotifications;
  }

  hideNotificationDropdown() {
    this.hideNotifications = true;
  }

  showUpArrow(id) {
    const element = document.querySelector(id);
    return element.classList.contains("show");
  }

  getRouterLink(searchValue) {
    // console.log('this.searchData =', this.searchData);
    if (_.trim(searchValue) != '') {
      return "/products-list?searchString=" + _.trim(searchValue);
    } else {
      alert('Please enter value');
    }
  }

  routeTOStartAnOrder() {
    this.router.navigate(['/start-an-order']).then(() => {
      window.location.reload()
    })
  }

  async searchProducts(searchValue = '') {
    if (searchValue !== '') {
      this.searchData = searchValue;
    }
    if (_.trim(this.searchData) != '') {
      let fvalue = await this.commonAPIService.setStrUrlString(_.trim(this.searchData));
      const newRoute = `products/${fvalue}`;
      this.router.navigate([newRoute]);
      this.searchData = '';
    } else {
      alert('Please enter value');
    }
  }

  stopMoreClick(e) {
    e.stopPropagation();
  }

  closeChat() {
    // e.stopPropagation();
    // console.log('clicked outside',e.target);
    // console.log('status',this.chatsService.showFullPageChat);
    if (this.chatsService.isChatPanelOpen && this.route != 'chat' && !this.chatsService.isFullImageViewOpen) {
      this.chatsService.isChatPanelOpen = false;
      // console.log('clicked');
    }
    this.chatsService.isFullImageViewOpen = false;
  }

  goBack(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.orderService.isOrderSavedSuccessfully()) {
      this.router.navigateByUrl('');
    }
  }

}
