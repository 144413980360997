import { Component, OnInit } from '@angular/core';

import * as $ from 'jquery';

@Component({
  selector: 'app-nav-two-layout',
  templateUrl: './nav-two-layout.component.html',
  styleUrls: ['./nav-two-layout.component.scss']
})

export class NavTwoLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    //Solution dropdown code New catalog page function:
    $(document).ready(function () {
      // Add class on button click
      $(".up__solution-btn").on("click", function (event) {
        event.stopPropagation(); // Prevent click from propagating to document
        $(".up__dropdown").toggleClass("active"); // Toggle class on up__dropdown
      });

      // Remove class on clicking outside
      $(document).on("click", function () {
        $(".up__dropdown").removeClass("active");
      });

      // Remove class on clicking other buttons (if needed)
      $(".up__solution-btn").siblings().on("click", function () {
        $(".up__dropdown").removeClass("active");
      });
    });
  }

}
