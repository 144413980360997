<div class="system-loader loading-overlay" [hidden]="!nav?.showSystemLoader">
    <paper-spinner active></paper-spinner>
</div>

<div id="outerAppContainer" [ngClass]="{'background-white': checkRoute() }" (click)="closeChat()">
    <div id="innerAppContainer" [ngClass]="{'background-white': checkRoute() }">

        <div
            class="header-wrapper bg-white {{(authService.isUserAuthorized()) ? 'headerAuthorized' : 'headerNotAuthorized'}}">
            <nav class="navbar navbar-expand-lg navbar-light bg-white container-fuild">
                <button class="navbar-toggler" type="button" (click)="toggleSidebar()">
                    <img src="/assets/images/iconmonstr-menu-1.svg" alt="" srcset="">
                </button>
                <a class="navbar-brand {{(authService.isUserAuthorized()) ? 'authorized' : 'notAuthorized'}}" href="#"
                    (click)="goBack($event)" [ngClass]="{'hide': searchIsActive}">
                    <img src="/assets/images/upmerch-logo-dark-latest.svg" alt="">
                </a>
                <div class="search-field" *ngIf="searchIsActive">
                    <div class="search-wrapper">
                        <input class="form-control search-input mr-sm-2" type="search" (keyup.enter)="searchProducts()"
                            [(ngModel)]="searchData" aria-label="Search">
                        <div class="search-button" (click)="searchProducts()">
                            <img src="/assets/images/iconmonstr-magnifier-6.svg" alt="">
                        </div>
                    </div>
                    <img (click)="showSearch()" class="close-button" src="/assets/images/iconmonstr-x-mark-7.svg"
                        alt="">
                </div>
                <div class="m-icons" [ngClass]="{'hide': searchIsActive}">
                    <img src="/assets/images/iconmonstr-magnifier-6.svg" (click)="showSearch()" alt="">

                    <div class="action" *ngIf="authService.isUserAuthorized()">
                        <chat-notification-popup [auth]="auth"></chat-notification-popup>
                    </div>
                    <div class="action" *ngIf="authService.isUserAuthorized()">
                        <app-notification-popup [auth]="auth"></app-notification-popup>
                    </div>
                    <a [routerLink]="['/profile/edit',authService.auth.uid]" *ngIf="authService.auth">
                        <div class="profile">
                            <span>{{user?.name?.substring(0, 2) | uppercase}}</span>
                        </div>
                    </a>
                </div>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav left-panel">
                        <li class="nav-item dropdown">
                            <a class="nav-link active custom-dropdown" href="#" id="navbarDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Brands &nbsp; <i class="im im-angle-down"></i><i class="im im-angle-up"></i>
                            </a>
                            <div class="dropdown-menu nav-dropdown-width" aria-labelledby="navbarDropdown">
                                <i class="fa fa-sort-asc brand-sort-asc" aria-hidden="true"></i>
                                <div class="row" *ngIf="brands">
                                    <ng-container *ngFor="let brand of brands">
                                        <div class="col-md-6 header-brand">
                                            <a
                                                routerLink="/products/{{brand | removeSpaceConvertUnderScopeLowerCase}}">{{brand}}</a>
                                        </div>
                                    </ng-container>
                                    <div class="col-md-12 text-center mt-2 header-view-all">
                                        <a href="/">View All</a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link active custom-dropdown" href="#" id="navbarDropdown2" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Categories &nbsp;<i class="im im-angle-down"></i><i class="im im-angle-up"></i>
                            </a>
                            <div class="dropdown-menu nav-dropdown-width" aria-labelledby="navbarDropdown2">
                                <i class="fa fa-sort-asc cat-sort-asc" aria-hidden="true"></i>
                                <div class="row" *ngIf="categories">
                                    <ng-container *ngFor="let cat of categories">
                                        <div class="col-md-6 header-category">
                                            <a
                                                routerLink="/products/{{cat | removeSpaceConvertUnderScopeLowerCase}}">{{cat}}</a>
                                        </div>
                                    </ng-container>
                                    <div class="col-md-12 text-center mt-2 header-view-all">
                                        <a href="/">View All</a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <form class="center-panel mx-auto form-inline my-2 my-lg-0">
                        <div class="search-wrapper">
                            <input class="form-control search-input mr-sm-2" type="search"
                                (keyup.enter)="searchProducts()" id="searchData" name="searchData" aria-label="Search"
                                [(ngModel)]="searchData">
                            <div class="search-button" (click)="searchProducts()">
                                <img src="/assets/images/iconmonstr-magnifier-6.svg" alt="">
                            </div>
                        </div>
                    </form>
                    <div class="right-panel">
                        <ul class="navbar-nav">
                            <li class="nav-item dropdown permission-menu"
                                *ngIf="authService.auth && authService.isUserAuthorized() && user?.permissionLevel !=='Not Authorized'">
                                <a class="nav-link active custom-dropdown" href="#" id="navbarDropdown" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{user?.permissionLevel == "Seller" ? "Sales Partner" : user?.permissionLevel}}
                                    <i class="im im-angle-down"></i><i class="im im-angle-up"></i>
                                </a>
                                <div class="dropdown-menu role-dropdown" aria-labelledby="navbarDropdown">
                                    <i class="fa fa-sort-asc brand-sort-asc" aria-hidden="true"></i>
                                    <div class="row menu-options">
                                        <div class="col-md-5 first-side">
                                            <ng-container
                                                *ngIf="authService.isUserAuthorized() && (authService.isUserSalesPartner() || authService.isUserAdmin() || authService.isUserCSD() || authService.isUserOM())">
                                                <div class="sub-head"><span class="cursor-default">Sales</span></div>
                                                <div
                                                    [ngClass]="{'space-align': authService.isUserAuthorized() && (authService.isUserSalesPartner() || authService.isUserAdmin() || authService.isUserCSD() || authService.isUserOM())}">
                                                    <a routerLink="/sales">Orders</a>
                                                </div>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="authService.isUserAuthorized() && (authService.isUserDesignPartner() || authService.isUserAdmin() || authService.isUserCSD()|| authService.isUserOM())">
                                                <div class="sub-head"><span class="cursor-default">Design</span></div>
                                                <div
                                                    [ngClass]="{'space-align': authService.isUserAuthorized() && (authService.isUserDesignPartner() || authService.isUserAdmin() || authService.isUserCSD() || authService.isUserOM())}">
                                                    <a routerLink="/design"> Orders</a>
                                                </div>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="authService.isUserAuthorized() && (authService.isUserProductionPartner() || authService.isUserAdmin() || authService.isUserCSD() || authService.isUserOM())">
                                                <div class="sub-head"><span class="cursor-default">Production</span>
                                                </div>
                                                <div><a routerLink="/production"> Orders</a></div>
                                                <div
                                                    [ngClass]="{'space-align': authService.isUserAuthorized() && (authService.isUserProductionPartner() || authService.isUserAdmin() || authService.isUserCSD() || authService.isUserOM())}">
                                                    <a routerLink="/production/pricing">Pricing</a>
                                                </div>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="(authService.isUserAdmin() || authService.isUserCSD() || authService.isUserOM())">
                                                <div class="sub-head"><span class="cursor-default"> Settings</span>
                                                </div>
                                                <div
                                                    [ngClass]="{'space-align': (authService.isUserAdmin() || authService.isUserCSD() || authService.isUserOM())}">
                                                    <a routerLink="/admin">Users</a>
                                                </div>
                                            </ng-container>
                                            <ng-container>
                                                <div class="text-bottom">
                                                    <a class="logout-text" (click)="authService.logout()">
                                                        Log Out
                                                    </a>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div class="col-md-7">
                                            <ng-container
                                                *ngIf="authService.isUserAuthorized() && (authService.isUserAdmin() || authService.isUserCSD() || authService.isUserOM())">
                                                <div class="sub-head"><span class="cursor-default">Products</span></div>
                                                <div><a routerLink="/"> Catalog </a></div>
                                                <div><a routerLink="/admin/products/manage"> Manage </a></div>
                                                <div><a routerLink="/admin/products/categories"> Categories </a></div>
                                                <div><a routerLink="/admin/products/colors"> Colors </a></div>
                                            </ng-container>
                                            <ng-container>
                                                <div class="sub-head" (click)="$event.preventDefault();"><span
                                                        class="cursor-default">Resources</span></div>
                                                <div
                                                    *ngIf="authService.isUserAuthorized() && !authService.isUserDesignPartner() && !authService.isUserProductionPartner()">
                                                    <a href="http://resources.upmerch.com/en/" target="_blank">
                                                        Knowledge Library </a>
                                                </div>
                                                <div
                                                    *ngIf="authService.isUserAuthorized() && authService.isUserDesignPartner()">
                                                    <a href="https://www.notion.so/upmerch/Design-Partner-89491751d23f423bbbfced3491514d13"
                                                        target="_blank"> Resource Center </a>
                                                </div>
                                                <div><a href="https://form.asana.com/?k=oh_5Sucn7zkDtYsVP8LVuQ&d=1195957427643197"
                                                        target="_blank"> Report Bug </a></div>
                                                <div><a href="https://form.asana.com?k=arcyiWpebjrU-qyBk91sLw&d=1195957427643197"
                                                        target="_blank"> Request Feature </a></div>

                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <ng-container *ngIf="authService.isUserAuthorized() != null">
                                <li class="nav-item dropdown"
                                    *ngIf="authService.isUserAuthorized() && user?.permissionLevel =='Not Authorized'">
                                    <a class="nav-link active">{{user?.permissionLevel}}</a>
                                </li>
                                <li class="nav-item dropdown" *ngIf="authService.isAuthorized == false">
                                    <a class="nav-link active" (click)="authService.logout()" [routerLink]="['/login']">
                                        Log Out
                                    </a>
                                </li>
                            </ng-container>
                            <li class="nav-item dropdown"
                                *ngIf="authService.isLoading == false && (authService.isUserAuthorized() == false || authService.isUserAuthorized() == null)">
                                <a class="nav-link active" [routerLink]="['/login']">
                                    Partners
                                </a>
                            </li>

                            <li class="nav-item dropdown"
                                *ngIf="authService.isLoading == false && (authService.isUserAuthorized() == false || authService.isUserAuthorized() == null)">
                                <div class="nav-link active start-an-order-btn" (click)="routeTOStartAnOrder()">
                                    Start an Order
                                </div>
                            </li>
                        </ul>

                        <ng-container *ngIf="authService.isUserAuthorized() != null">
                            <div class="action" *ngIf="authService.isUserAuthorized()">
                                <chat-notification-popup [auth]="auth"></chat-notification-popup>
                            </div>
                            <div class="role-menager">
                                <div class="action" *ngIf="authService.isUserAuthorized()">
                                    <app-notification-popup [auth]="auth"></app-notification-popup>
                                </div>
                            </div>
                            <a [routerLink]="['/profile/edit',authService.auth.uid]" style="text-decoration: none;">
                                <div class="profile">
                                    <span>{{user?.name?.substring(0, 2) | uppercase}}</span>
                                </div>
                            </a>
                        </ng-container>
                    </div>
                </div>
            </nav>
        </div>


        <app-sidebar *ngIf="isSidebarOpen" class="m-sidebar"></app-sidebar>

        <app-image-view></app-image-view>

        <div id="routerOutletDiv"
            class="content product-content smallOutletContainer background-update {{(route.length > 0) ? route + '-page-content' : 'login-page-content'}}"
            [ngClass]="{'background-white': checkRoute()}" infiniteScroll [infiniteScrollDistance]="2"
            [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" (scrolled)="onScroll()"
            [scrollWindow]="false" [ngClass]="{'modal-open' : isModalOpen}"
            [ngStyle]="{'overflow': route.length >0 && route == 'chat' ? 'visible' :'unset'}">
            <router-outlet></router-outlet>
        </div>
    </div>

    <div *ngIf="authService.isUserAuthorized() != null"
        [ngClass]="{'when-chat-panel-open': chatsService?.isChatPanelOpen, 'when-ios': nav?.isIOSDevice}"
        [hidden]="!nav?.showOrderChatIcon" class="chats-option">
        <app-chats [inputOrderId]="chatsService.orderId" [showOrderChatIcon]="nav.showOrderChatIcon"
            (click)="stopMoreClick($event)"></app-chats>
    </div>
    <div [ngClass]="{'popup-container': chatsService?.showConfirmDelete}" id="popupContainer">
    </div>
</div>