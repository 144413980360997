<!-- nav-one-layout.component.html -->
<div class="layout-container">
    <div class="content-wrapper">
        <!-- START :: NAVBAR -->
        <div class="up__navbar-sec">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="up__nav-logo col-sm-6">
                        <a href="/" class="navbar-brand notAuthorized">
                            <img _ngcontent-qch-c70="" src="/assets/images/upmerch-logo-dark-latest.svg" alt="">
                        </a>
                    </div>
                    <div class="up__dropdown flex justify-content-end col-sm-6 position-relative">
                        <div class="flex justify-content-end align-items-center up__solution-btn">
                            <h4 class="m-0">Solutions</h4>
                            <div class="up__navbar flex flex-column ml-2">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div class="up__solutions-dropdown">
                            <div class="d-flex flex-wrap justify-content-between up__solutions-dropdownwrap">
                                <div class="up__organization-wraps">
                                    <a href="https://www.upmerch.com/merch-store">
                                        <div class="d-flex flex-wrap align-items-center up__organization-headerblock">
                                            <div class="up__img-block"><img alt="highquality_4"
                                                    src="/assets/images/product-catalog/Solution-2.jpg" loading="lazy"
                                                    height="98" width="98" sizes="(max-width: 98px) 100vw, 98px"></div>
                                            <div class="d-flex up__content">
                                                <div class="up__content-top">
                                                    <h3>Merch Store</h3>
                                                    <p class="text">Always Open,
                                                        <br> Inventory Pre-Purchased.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="up__organization-wraps">
                                    <a href="https://www.upmerch.com/merch-bundles">
                                        <div class="d-flex flex-wrap align-items-center up__organization-headerblock">
                                            <div class="up__img-block"><img alt="homehero_merchbundles"
                                                    src="/assets/images/product-catalog/Solution-3.jpg" loading="lazy"
                                                    height="98" width="98" class="up__main-img"
                                                    sizes="(max-width: 98px) 100vw, 98px"></div>
                                            <div class="d-flex up__content">
                                                <div class="up__content-top">
                                                    <h3>Merch Bundles</h3>
                                                    <p class="text">Employee &amp; Customer
                                                        <br> Gifting Made Easy
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="up__organization-wraps">
                                    <a href="https://www.upmerch.com/merch-storage">
                                        <div class="d-flex flex-wrap align-items-center up__organization-headerblock">
                                            <div class="up__img-block"><img alt="highquality_4"
                                                    src="/assets/images/product-catalog/Solution-4.jpg" loading="lazy"
                                                    height="98" width="98" sizes="(max-width: 98px) 100vw, 98px"></div>
                                            <div class="d-flex up__content">
                                                <div class="up__content-top">
                                                    <h3>Merch Storage</h3>
                                                    <p class="text">Your On-Demand,
                                                        <br> Warehouse &amp; Fulfillment.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="up__organization-wraps">
                                    <a href="https://www.upmerch.com/merch-drop">
                                        <div class="d-flex flex-wrap align-items-center up__organization-headerblock merch-drop-block">
                                            <div class="up__img-block"><img alt="highquality_4"
                                                    src="/assets/images/product-catalog/Solution-1.jpg" loading="lazy"
                                                    height="98" width="98" sizes="(max-width: 98px) 100vw, 98px"></div>
                                            <div class="d-flex up__content">
                                                <div class="up__content-top">
                                                    <h3>Merch Drop</h3>
                                                    <p class="text">Timed Online Store.
                                                        <br> No Risk, No Inventory.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END :: NAVBAR -->
        <router-outlet></router-outlet> 
        <!-- START :: FOOTER SECTION -->
        <div class="up__footer">
            <div class="up__footer-logo-n-email">
                <div>
                    <a href="#" class="up__footer-logo">
                        <img _ngcontent-qch-c70="" src="/assets/images/product-catalog/upmerch-modern-dark-footer.svg"
                            alt="">
                    </a>
                    <p class="up__footer-label_layout">&#169; UpMerch 2023. All rights reserved.</p>
                </div>
                <div>
                    <p class="up__footer-newsletter-title">Signup for our Newsletter</p>
                    <div class="up__footer-email-div">
                        <input type="email" id="email" name="email" aria-label="email" placeholder="Email Address">
                        <button>
                            <img class="arrow-icon" src="/assets/images/product-catalog/arrow-right-icon-email.svg"
                                alt="">
                        </button>
                    </div>
                    <div class="social-media-logo-div">
                        <a href="https://www.tiktok.com/@upmerch.hq">
                            <img src="/assets/images/product-catalog/tiktok.svg" alt="TikTok">
                        </a>
                        <a href="https://www.instagram.com/upmerch/">
                            <img src="/assets/images/product-catalog/insta.svg" alt="Instagram">
                        </a>
                        <a href="https://www.linkedin.com/company/upmerch/">
                            <img src="/assets/images/product-catalog/linkedin.svg" alt="LinkedIn">
                        </a>
                        <a href="https://www.facebook.com/upmerch.hq">
                            <img src="/assets/images/product-catalog/facebook.svg" alt="Facebook">
                        </a>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <a class="up__footer-label_layout up-terms-n-cond" href="https://shop.upmerch.com/policies/terms-of-service">
                    Terms and Conditions
                </a>
                <a class="up__footer-label_layout" href="https://shop.upmerch.com/policies/privacy-policy">
                    Privacy Policy
                </a>                
            </div>
        </div>
        <!-- END :: FOOTER SECTION -->
    </div>
</div>