import { Component, OnInit } from '@angular/core';
import { InfiniteService } from './utils/services/infinite.service';
@Component({
  selector: 'ng-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private InfityService: InfiniteService,
  ) {}

  async ngOnInit() {

  }

  onScroll() {
    this.InfityService.scroll();
  }

}
